export default {
    makeNestedMenu(_menuItems, _access) {
        let _nestedMenu = [];
        _menuItems.forEach(mItem => {
            let _menuItem = Object.assign({}, mItem)
            if (_menuItem.children == undefined) {
                let _checkMenuItem = _access.findIndex(_item => _item.routeName == _menuItem.routeName)
                if (_checkMenuItem > -1) _nestedMenu.push(_menuItem)
            } else {
                _menuItem.children = this.makeNestedMenu(_menuItem.children, _access)
                if (_menuItem.children.length) _nestedMenu.push(_menuItem)
            }
        });
        return _nestedMenu
    },
}