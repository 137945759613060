<template>
    <v-app>
        <v-navigation-drawer
            :clipped="clipped"
            fixed
            app
            dark
            color="blue-gray darken-4"
            width="270"
            v-if="drawer"
        >
            <v-treeview :items="$store.getters['auth/permittedMenuItems']" item-key="name">
                <template v-slot:label="{ item }">
                    <span v-if="item.children">
                        <v-icon
                            small
                            v-if="item.icon"
                            :color="item.color"
                            class="mr-1"
                            >{{ item.icon }}</v-icon
                        >
                        {{ item.name }}
                    </span>
                    <router-link :to="item.link" v-else>
                        <div>
                            <v-icon
                                small
                                v-if="item.icon"
                                :color="item.color"
                                class="mr-1"
                                >{{ item.icon }}</v-icon
                            >
                            {{ item.name }}
                        </div>
                    </router-link>
                </template>
            </v-treeview>
        </v-navigation-drawer>
        <v-app-bar
            :clipped-left="clipped"
            fixed
            app
            dense
            color="blue lighten-1"
            dark
            flat
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            <v-toolbar-title>
                {{ this.$store.getters['companyProfile/company'] != null ? this.$store.getters['companyProfile/company'].company_name : '' }}
            </v-toolbar-title>
            <v-spacer />
            {{
                time.toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    weekday: "long",
                })
            }}

            {{
                time.toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: true,
                })
            }}
            <v-btn icon @click.stop="rightDrawer = !rightDrawer">
                <v-icon>mdi-account-circle</v-icon>
            </v-btn>
        </v-app-bar>
        <v-main>
            <router-view></router-view>
        </v-main>
        <v-navigation-drawer
            v-model="rightDrawer"
            :right="true"
            :hide-overlay="true"
            temporary
            fixed
            light
        >
            <template v-slot:prepend>
                <v-list-item two-line>
                    <v-list-item-avatar class="mr-1">
                        <v-icon x-large color="primary"
                            >mdi-account-circle</v-icon
                        >
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{ userFullName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ userName }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <v-divider></v-divider>

            <v-list dense nav class="mt-2">
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon>mdi-account-details</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <router-link to="/user-profile-setting">
                            <v-list-item-title class="grey--text text--darken-4">Profile Setting</v-list-item-title>
                        </router-link>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title @click="logout">Sign Out</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-footer :fixed="fixed" app dark color="#424242">
            <v-spacer></v-spacer>
            <!-- <div style="font-size:13px">&copy; 2020 | Developed by <a href="https://bigsoftwareltd.com" target="_blank">Big Software Limited</a></div> -->
            <div style="font-size:13px">&copy; 2020 | Developed by <a href="http://linktechbd.com" target="_blank">Link-Up Technology Ltd.</a></div>
        </v-footer>
        <snackbar></snackbar>
    </v-app>
</template>

<script>
import Snackbar from '../components/Snackbar'
import Utils from '@/utils'

export default {
    components: {
        Snackbar
    },
    data() {
        return {
            userFullName: '',
            userName: '',
            time: '',
            clipped: true,
            drawer: true,
            fixed: false,
            right: true,
            rightDrawer: false,
        };
    },

    watch: {
        async $route(to, from) {
            let checkAuth = await this.$store.dispatch('auth/checkAuth')
            if (checkAuth.success) {
                this.checkRouteAuthorization(checkAuth.user_type, checkAuth.permissions)
            } else {
                localStorage.setItem('_accessToken', '')
                this.$router.push('/login')
            }
        }
    },

    async created() {
        setInterval(() => (this.time = new Date()), 1000);

        let checkAuth = await this.$store.dispatch('auth/checkAuth')
        if (checkAuth.success) {
            let permissions = checkAuth.permissions
            this.loadMenu(checkAuth.user_type, permissions)
            this.checkRouteAuthorization(checkAuth.user_type, permissions)

            let userData = JSON.parse(localStorage.getItem('_userData'))
            this.userFullName = userData.name
            this.userName = userData.username
        } else {
            localStorage.setItem('_accessToken', '')
            this.$router.push('/login')
        }

        this.$store.dispatch('companyProfile/getCompanyProfile')
    },

    methods: {
        logout() {
            this.$store.commit('auth/populatePermittedMenuItems', [])
            this.$store.dispatch('auth/logout');
            this.$router.push('/login')
        },

        loadMenu(user_type, permittedMenuItems) {
            let menuItems = this.$store.getters['layout/menuItems']
            if (user_type == 'super_admin') {
                this.$store.commit('auth/populatePermittedMenuItems', menuItems)
            } else {
                if (!permittedMenuItems.length) return
                let pMenuItems = Utils.makeNestedMenu(menuItems, permittedMenuItems)
                this.$store.commit('auth/populatePermittedMenuItems', pMenuItems)
            }
        },

        checkRouteAuthorization(userType, _permissions) {
            let permissions = _permissions || []
            if (userType == 'super_admin') return
            if (this.$route.name == 'user-profile-setting') return
            if (this.$route.name == 'test-receipt-slip') return
            if (this.$route.name == 'investigation-report') return
            if (this.$route.name == 'TransactionInvoice') return
            let hasPermit = permissions.findIndex(item => item.routeName == this.$route.name)
            if (hasPermit == -1) this.$route.path != '/' ? this.$router.push('/') : null
        }
    },
};
</script>

<style lang="scss" scoped>

</style>
